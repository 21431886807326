import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
const FairAndEventServices = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      events: file(relativePath: { eq: "events.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-108" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.events.childImageSharp.fluid}
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-108" })}</div>
                </div>

                <h6>{intl.formatMessage({ id: "innerpage-109" })}</h6>
                <ul>
                    <li>{intl.formatMessage({ id: "innerpage-110" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-111" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-112" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-113" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-114" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-115" })}</li>
                </ul>
                <h6>{intl.formatMessage({ id: "innerpage-116" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-117" })}</p>
                <ul>
                    <li>{intl.formatMessage({ id: "innerpage-118" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-119" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-120" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-121" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-122" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-123" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-124" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-125" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-126" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-127" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-128" })}</li>
                </ul>

                <h6>{intl.formatMessage({ id: "innerpage-129" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-130" })}</p>
                <ul>
                    <li>{intl.formatMessage({ id: "innerpage-131" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-132" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-133" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-134" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-135" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-136" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-137" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-138" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-139" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-140" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-141" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-142" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-143" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-144" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-145" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-146" })}</li>
                </ul>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default FairAndEventServices;
